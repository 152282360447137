<template>
  <div class="container is-fluid overview">
    <div class="tabs is-boxed">
      <ul>
        <li v-for="route in navigation" :key="route.path" :class="{'is-active': $route.name === route.name}">
          <router-link :to="{name: route.name}">
            <span class="icon is-small"> <svg-icon :icon="route.name" /></span>
            <span>
              {{ $t(route.meta.title) }}
            </span>
          </router-link>
        </li>
        <li style="margin-left: auto">
          <download-excel
            class="button is-primary bg-cyan"
            :data="this.$store.state.orders"
            :fields="json_fields"
            type="xls"
            worksheet="My Worksheet"
            :name="'Pick-up ['+lastPickUpDate+'].xls'"
          >
            {{ $tc('overview.pick-up') }}
          </download-excel>
        </li>
        <li style="margin-left: 10px">
          <router-link
            class="is-primary bg-cyan c-white"
            :to="{ name: 'selector' }"
          >{{ $tc('overview.create', 'order') }}</router-link>
        </li>
      </ul>
    </div>
    <div class="overview-wrapper">
      <router-view :key="routeName"></router-view>
    </div>
  </div>
</template>
<script>
export default {
  name: "Overview",
  data() {
    return {
      navigation: [],
      routeName: 123,
      json_fields: {
      Nummer: "id",
      Referentie: "projectreferentie",
      Opdrachtgever: "kl_contactpers",
      Deadline:{ 
        field: "datumtijddeadline",
        callback: (value)=> {
          let datum = new Date(value)
        return datum.getDate()+"-"+datum.getMonth()+'';
      } },
    },
     json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.routeName = to.name;
    next();
  },
  created() {
    this.navigation = this.$router.options.routes.find(route => route.name === "overview").children
    if (this.$route.name === 'overview') {
      this.$router.push({name: 'orders'});
    }
  },
  methods:{
  },
  computed:{
      lastPickUpDate: function(){
      let lastDate = new Date('2010/12/31');
      if(this.$store.state.orders !== null){
       this.$store.state.orders.forEach(order =>{
        let currentDate = new Date(order.datumtijddeadline)
        if(currentDate > lastDate)
        {
          lastDate = currentDate;
        }
      })
      }
      return lastDate.getDate()+"-"+(lastDate.getMonth()+1);
    }
  }
};
</script>
